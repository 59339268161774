import styled from '@emotion/styled';

const SlideInComponent = styled.div`
  .slide-in {
    position: fixed;
    top: 0;
    right: -50%; /* Initially off-screen */
    width: 50%;
    height: 100%;
    background-color: #fff; /* Customize background color */
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1); /* Add shadow */
    border-left: 1px solid #ddd; /* Add border */
    transition: right 0.5s ease; /* Smooth sliding animation */
    z-index: 1000; /* Ensure it stays on top */
  }

  .slide-in.active {
    right: 0; /* When active, slide in */
  }

  .slide-in-content {
    padding: 20px;
    padding-top: 60px;
    /* Add your styles for the content */
  }

  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    /* Style the heading */
  }

  .toggle-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    /* Style the button */
  }
`;

export default SlideInComponent;
