import { useEffect, useState, useRef, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography as MuiTypography } from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { formatToSEK } from '../../utils/currencyFormatter';

import Stats from '../../pages/dashboards/Default/Stats';
import { getAdminDashboard } from '../../API/usersApi';
import useAdminDashboardChart from '../../hooks/useAdminDashboardChart';
import Loader from '../Loader';
import AverageLeadsChart from './AverageLeadsChart';
import LeadChart from './LeadsChart';
import TotalBudgetCurrentMonth from './SalesCharts/TotalBudgetCurrentMonth';
import ActiveClientChart from '../Charts/ActiveClientChart';
import AverageRatingChart from './AverageRatingChart';
import SlideInComponent from './SlideInComponent';
import useAuth from '../../hooks/useAuth';

const Typography = styled(MuiTypography)(spacing);

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const { userMe } = useAuth();
  const [salesData, setSalesData] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const slideInRef = useRef(null);

  const { currentMonthChartData, yearChartData, currentMonthChartDataRef, yearChartDataRef, filteredUsers, totalRes } = useAdminDashboardChart();

  const { data: stats } = useQuery({
    queryKey: ['adminDashboard'],
    queryFn: getAdminDashboard,
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (slideInRef.current && !slideInRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSitesClick = useCallback(
    (value) => {
      navigate(`/${userMe}/sites?isAvailable=${value}`);
    },
    [navigate, userMe],
  );

  const handleChartClick = (event, activeUser) => {
    const activeSalesData = { event, activeUser, data: [] };
    const matchingUser = filteredUsers.find((user) => user.email.includes(activeUser));
    if (!matchingUser) return;

    const userChartDataForUser = currentMonthChartDataRef.current[matchingUser.email];
    switch (event) {
      case 'Försäljning - Nykund':
        activeSalesData.data = userChartDataForUser.new_client_result;
        break;
      case 'Försäljning - Befintlig kund':
        activeSalesData.data = userChartDataForUser.renewed_result;
        break;
      case 'Budget - Befintliga kunder':
        activeSalesData.data = userChartDataForUser.potential_renewal_clients;
        break;
      case 'Budget - Nykund':
        activeSalesData.data = userChartDataForUser.new_client_budget;
        break;
      case 'Churn':
        activeSalesData.data = userChartDataForUser.end_date_clients;
        break;
      default:
        break;
    }

    setSalesData(activeSalesData);
    setIsActive(true);
  };

  if (
    !stats ||
    !currentMonthChartData ||
    !yearChartData ||
    !filteredUsers ||
    !currentMonthChartDataRef ||
    !yearChartDataRef ||
    !totalRes.newClient
  ) {
    return <Loader />;
  }

  return (
    <>
      <div ref={slideInRef}>
        <SlideInComponent data={salesData} isActive={isActive} />
      </div>
      <Grid container justifyContent="space-between" spacing={6}>
        <Grid item sx={{ marginBottom: '20px' }}>
          <Typography
            sx={{
              fontWeight: 500,
              '@media (max-width: 600px)': {
                fontSize: '30px',
              },
            }}
            variant="h1"
            gutterBottom
          >
            {stats.companyName || 'Dashboard'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={10}>
          <TotalBudgetCurrentMonth data={currentMonthChartData} handleChartClick={handleChartClick} />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Total budget</div>}
            amount={formatToSEK(totalRes.total.budget)}
            chip="kr"
          />
          <Stats
            title={<div style={{ paddingRight: 55 }}>Total utfall</div>}
            amount={formatToSEK(totalRes.total.result)}
            chip="kr"
          />
          <Stats
            title={<div style={{ paddingRight: 55 }}>Total utfall</div>}
            amount={`${totalRes.total.percentage}%`}
            chip="%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 60 }}>Leads per kund </div>}
            amount={stats.averageLeadsPerClientLast30Days}
            chip="30 days"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Snitt ranking</div>}
            amount={stats.averageGoogleRank}
            chip="Annual"
          />
        </Grid>
        <Grid sx={{ cursor: 'pointer' }} onClick={() => handleSitesClick(1)} item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Lediga sidor</div>}
            amount={stats.amountOfAvailableSites}
            chip="Annual"
          />
        </Grid>
        <Grid sx={{ cursor: 'pointer' }} onClick={() => handleSitesClick(0)} item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Sidor med kunder</div>}
            amount={stats.amountOfNotAvailableSites}
            chip="Annual"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Antal Kunder</div>}
            amount={stats.usersCount}
            chip="Annual"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AverageLeadsChart leadsCount={stats.leadsCounts} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LeadChart leadsInDay={stats.leadsInDay} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ActiveClientChart leadsCount={stats.countActiveCompany} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AverageRatingChart chartData={stats.averageGoogleRankPerYear} />
        </Grid>
      </Grid>
    </>
  );
};

export default AdminDashboardPage;
