import { TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../utils/timeJs';
import { formatToSEK } from '../../utils/currencyFormatter';

const SalesTableRow = ({ sale }) => {
  const tableRow = {};
  if (sale.metadata) {
    tableRow.name = sale.client.name;
    tableRow.date = sale.metadata.agreementRenewalDate;
    tableRow.value = sale.yearlyValue * (sale.metadata.periodLength / 12);
  } else {
    tableRow.name = sale.client.name;
    tableRow.date = sale.closeDate;
    tableRow.value = sale.value;
  }
  if (sale.isRenewed) {
    tableRow.isRenewed = sale.isRenewed;
  }
  return (
    <TableRow sx={{ backgroundColor: tableRow.isRenewed ? '#82296C' : 'inherit' }}>
      <TableCell sx={{ whiteSpace: 'nowrap', color: tableRow.isRenewed ? 'white !important' : 'inherit' }}>{tableRow.name}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', color: tableRow.isRenewed ? 'white !important' : 'inherit' }}>{formatDate(tableRow.date)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', color: tableRow.isRenewed ? 'white !important' : 'inherit', textAlign: 'center' }}>{formatToSEK(tableRow.value)}</TableCell>
    </TableRow>
  );
};

export default SalesTableRow;
