import {
  Table,
  TableBody,
  Card,
  TablePagination,
  CircularProgress,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
// import { usePagination } from '../../hooks/table/usePagination';
import SalesTableHeader from '../../components/Sales/SalesList/SalesTableHeader';
import SalesTableRow from './SalesTableRow';
import { TableWrapper } from '../../components/styled/TableWrapper';

const SalesListContainer = ({ salesData }) => {
  // const { page, limit, handlePageChange, handleLimitChange } = usePagination({
  //     initialPage: 1,
  //     initialLimit: 10,
  //   });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  const testFunction = (value) => {
    console.log('test', value);
    setLimit(value);
  };

  useEffect(() => {
    const filtered = salesData.data.filter((sale) => {
      const compareValue = sale.yearlyValue !== undefined ? sale.yearlyValue : sale.value;
      return compareValue !== 0;
    });
    setCount(filtered.length);
    setFilteredData(filtered);
  }, [salesData]);

  if (!salesData || !salesData.data) {
    return <CircularProgress />;
  }

  const sortedData = [...filteredData].sort((a, b) => {
    const dateA = a.metadata?.agreementRenewalDate ? new Date(a.metadata.agreementRenewalDate) : new Date(a.closeDate);
    const dateB = b.metadata?.agreementRenewalDate ? new Date(b.metadata.agreementRenewalDate) : new Date(b.closeDate);
    return dateA - dateB;
  });

  return (
    <Card sx={{
      maxHeight: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Prevent double scrollbar
    }}>
      <Box sx={{ padding: '16px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <h2 style={{ margin: 0, textTransform: 'capitalize' }}>{salesData.activeUser.split('@')[0].replace('.', ' ')}</h2>
        <h2 style={{ textTransform: 'capitalize', position: 'absolute', right: '20px', top: '4rem' }}>{salesData.event}</h2>
      </Box>

      <TableWrapper sx={{ flex: '1 1 auto', overflow: 'auto', minHeight: 0 }}>
        <Table stickyHeader>
          <SalesTableHeader salesType={salesData.event} />
          <TableBody>
            {sortedData
              .slice((page - 1) * limit, (page - 1) * limit + limit)
              .map((sale) => (
                <SalesTableRow key={sale.id} sale={sale} />
              ))}
          </TableBody>
        </Table>
      </TableWrapper>

      {count > 0 && (
        <TablePagination
          component="div"
          count={count}
          page={page - 1}
          onPageChange={(_, newPage) => setPage(newPage + 1)}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
          onRowsPerPageChange={(event) => testFunction(parseInt(event.target.value, 10))}
          sx={{ flex: '0 0 auto' }} // Prevent the pagination from shrinking
        />
      )}
    </Card>
  );
};

export default SalesListContainer;
