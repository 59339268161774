import { useContext, useMemo } from 'react';
import { Select, MenuItem } from '@mui/material';
import { generateMonthOptions } from '../../../utils/dateFormatter';
import { LeadsDashboardContext } from '../../../contexts/dashboard/LeadsDashboardContext';

const LeadsPerMonthSelect = () => {
  const { dates, setDate } = useContext(LeadsDashboardContext);
  const options = useMemo(() => generateMonthOptions(), []);
  const selectedValue = options.find((d) => d.label === dates.label) || options[0];
  return (
    <Select
      size="small"
      value={selectedValue}
      onChange={(e) => setDate(e.target.value)}
      style={{ maxWidth: '150px', height: '36px' }}>
      {options.map((item) => (
        <MenuItem key={item.label} value={item}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LeadsPerMonthSelect;
