import { useQuery } from '@tanstack/react-query';
import { getAllCategory } from '../API/categoryApi';

export const useCategories = () =>
  useQuery({
    queryKey: ['categories'],
    queryFn: getAllCategory,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
