import { memo, useContext } from 'react';
import { Box, Card, Typography } from '@mui/material';

import ApexChart from '../../ApexChart';
import { COLORS_FOR_PER_MONTH_CHART } from '../../../constants/chartData';
import LeadsPerMonthSelect from './LeadsPerMonthSelect';
import LeadsPerMonthSegmentSelect from './LeadPerMonthSegmentSelect';
import Loader from '../../Loader';
import { LeadsDashboardContext } from '../../../contexts/dashboard/LeadsDashboardContext';

const LeadsPerMonthChart = ({ data, isLoadingLeads }) => {
  const { category, setCategory } = useContext(LeadsDashboardContext);

  if (isLoadingLeads) return <Loader />;

  const options = {
    dataLabels: {
      enabled: false,
    },
    labels: Object.keys(data.intervals),
    colors: COLORS_FOR_PER_MONTH_CHART,
    tooltip: {
      y: {
        formatter: (value, { seriesIndex }) => {
          const label = Object.keys(data.intervals)[seriesIndex];
          const percent = data.percent[label] || 0;
          return `${value} (${percent}%)`;
        },
      },
    },
  };
  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '600px',
        padding: 3,
        '@media (min-width: 960px)': {
          padding: 10,
        },
        position: 'relative',
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: '18px',
                md: '24px',
              },
            }}
            gutterBottom>
            Leads Per Month Chart
          </Typography>
        </div>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            '@media (min-width: 960px)': {
              position: 'relative',
              right: '-15px',
              top: '-10px',
            },
          }}>
          <LeadsPerMonthSelect />
          <LeadsPerMonthSegmentSelect category={category} onChange={setCategory} />
        </Box>
      </div>

      {data && !Object.values(data.intervals).every((arr) => arr.length === 0) ? (
        <ApexChart
          data={Object.values(data.intervals).map((i) => i.length)}
          type="donut"
          options={options}
          height="auto"
          width="auto"
        />
      ) : (
        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
          <h3>No active companies</h3>
        </div>
      )}
    </Card>
  );
};

export default memo(LeadsPerMonthChart);
