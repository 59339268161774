import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getFormData, sendFormData } from '../../API/usersApi';
import Loader from '../Loader';

const FORM_CLEAR_STATE = {
  'Till vilken mejl vill ni ha kundförfrågningar?': '',
  'Till vilket telefonnummer vill ni ha kundförfrågningar?': '',
  'Vilken målgrupp vill ni rikta sidan mot?': {
    Privatpersoner: false,
    Företag: false,
    Bostadsrättsföreningar: false,
    'Offentligt verksamhet': false,
  },
  'Lista era tjänster och ge en kort beskrivning till respektive tjänst.': '',
  'Var är ni verksamma när det kommer till uppdrag?': '',
  'Annan info som ni tycker är viktigt att få med på sidan?': '',
  'Har ni några certifikat, diplom etc?': '',
};

const InfoForm = () => {
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const compliteSubmitt = async () => {
    setFormSubmitted(true);
    setTimeout(() => setFormSubmitted(false), 5000);
  };

  const formik = useFormik({
    initialValues: { ...FORM_CLEAR_STATE },
    validationSchema: Yup.object().shape({
      'Till vilken mejl vill ni ha kundförfrågningar?': Yup.string()
        .max(255)
        .required('Detta är ett obligatoriskt fält.'),
      'Till vilket telefonnummer vill ni ha kundförfrågningar?': Yup.string()
        .max(255)
        .required('Detta är ett obligatoriskt fält.'),
      'Lista era tjänster och ge en kort beskrivning till respektive tjänst.':
        Yup.string().required('Detta är ett obligatoriskt fält.'),
      'Var är ni verksamma när det kommer till uppdrag?': Yup.string().required(
        'Detta är ett obligatoriskt fält.',
      ),
      'Vilken målgrupp vill ni rikta sidan mot?': Yup.object().test(
        'at-least-one-checkbox',
        'Select at least one checkbox',
        (value) =>
          value.Privatpersoner ||
          value.Företag ||
          value.Bostadsrättsföreningar ||
          value['Offentligt verksamhet'],
      ),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await sendFormData(values);
        compliteSubmitt();
        console.log('Done');
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      const res = await getFormData();
      const result = JSON.parse(res.data);
      if (result !== null) {
        formik.setValues({ ...result });
      }
    };
    getData();
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (e.target.type === 'checkbox') {
        formik.setFieldValue('Vilken målgrupp vill ni rikta sidan mot?', {
          ...formik.values['Vilken målgrupp vill ni rikta sidan mot?'],
          [e.target.name]: e.target.checked,
        });
      } else {
        formik.setFieldValue([e.target.name], e.target.value);
      }
    },
    [formik],
  );
  return (
    <>
      {formSubmitted && (
        <Typography
          sx={{
            fontWeight: 700,
            color: 'green',
            bottom: '20px',
            position: 'fixed',
            left: '20px',
            zIndex: 2000,
            padding: '5px 10px',
            borderRadius: '10px',
            background: 'white',
          }}
          variant="p">
          Data saved
        </Typography>
      )}

      <Card
        sx={{
          backgroundImage:
            "url('https://www.addictivemedia.se/images/Addictive_Background-scaled.jpg')",
          backgroundSize: 'cover',
          margin: '-15px  -15px  0',
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 0,
          color: 'white',
          '@media (min-width: 800px)': {
            height: '400px',
            padding: '56px',
          },
          '@media (min-width: 1280px)': {
            margin: '-36px  -36px  0',
          },
        }}>
        <Typography
          sx={{
            margin: '20px 0',
            fontSize: '52px',
            '@media (max-width: 800px)': {
              fontSize: '34px',
            },
          }}
          variant="h1"
          component="div">
          Skicka in uppgifter
        </Typography>
        <Typography
          sx={{
            margin: '20px 0',
            fontSize: '18px',
            '@media (max-width: 800px)': {
              fontSize: '14px',
            },
          }}
          variant="p"
          component="div">
          Skapa förutsättningar för en bra landningssida
        </Typography>
      </Card>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '864px',
          margin: '0 auto',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}>
        <Typography sx={{ margin: '32px 0 26px' }} variant="h2" component="div">
          Ny kund
        </Typography>
        <Typography sx={{ fontSize: '18px' }} variant="p">
          Varmt välkommen som kund hos Addictive Media. För att kunna erbjuda de bästa
          förutsättningarna för din landningssida, skulle vi vara tacksamma om du kunde ta några
          minuter för att fylla i relevanta uppgifter här. Om vi inte får in något material kommer
          vi att basera oss på den information vi redan har. Det är naturligtvis också möjligt att
          göra justeringar på landningssidan i ett senare skede. Längst ner på sidan finner du även
          svar på de vanligaste frågorna.
          <br />
          <br /> Fält som är markerade med <span style={{ color: 'red' }}>*</span> är obligatoriska.
        </Typography>

        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Till vilken mejl vill ni ha kundförfrågningar?
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          name="Till vilken mejl vill ni ha kundförfrågningar?"
          value={formik.values['Till vilken mejl vill ni ha kundförfrågningar?']}
          onChange={handleChange}
          sx={{ bgcolor: 'white' }}
          variant="outlined"
          required
          error={Boolean(
            formik.touched['Till vilken mejl vill ni ha kundförfrågningar?'] &&
              formik.errors['Till vilken mejl vill ni ha kundförfrågningar?'],
          )}
        />
        {formik.touched['Till vilken mejl vill ni ha kundförfrågningar?'] &&
          formik.errors['Till vilken mejl vill ni ha kundförfrågningar?'] && (
            <Typography variant="p" sx={{ color: 'red' }}>
              {formik.errors['Till vilken mejl vill ni ha kundförfrågningar?']}
            </Typography>
          )}
        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Till vilket telefonnummer vill ni ha kundförfrågningar?
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          name="Till vilket telefonnummer vill ni ha kundförfrågningar?"
          value={formik.values['Till vilket telefonnummer vill ni ha kundförfrågningar?']}
          onChange={handleChange}
          sx={{ bgcolor: 'white' }}
          variant="outlined"
          required
          error={Boolean(
            formik.touched['Till vilket telefonnummer vill ni ha kundförfrågningar?'] &&
              formik.errors['Till vilket telefonnummer vill ni ha kundförfrågningar?'],
          )}
        />
        {formik.touched['Till vilket telefonnummer vill ni ha kundförfrågningar?'] &&
          formik.errors['Till vilket telefonnummer vill ni ha kundförfrågningar?'] && (
            <Typography variant="p" sx={{ color: 'red' }}>
              {formik.errors['Till vilket telefonnummer vill ni ha kundförfrågningar?']}
            </Typography>
          )}
        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Vilken målgrupp vill ni rikta sidan mot? (Flervalsalternativ)
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <FormGroup sx={{ paddingLeft: '10px' }}>
          <FormControlLabel
            name="Privatpersoner"
            onChange={handleChange}
            control={
              <Checkbox
                checked={formik.values['Vilken målgrupp vill ni rikta sidan mot?']?.Privatpersoner}
                sx={{ '&:hover': { bgcolor: 'transparent' } }}
              />
            }
            label="Privatpersoner"
          />
          <FormControlLabel
            name="Företag"
            onChange={handleChange}
            control={
              <Checkbox
                checked={formik.values['Vilken målgrupp vill ni rikta sidan mot?'].Företag}
                sx={{ '&:hover': { bgcolor: 'transparent' } }}
              />
            }
            label="Företag"
          />
          <FormControlLabel
            name="Bostadsrättsföreningar"
            onChange={handleChange}
            control={
              <Checkbox
                checked={
                  formik.values['Vilken målgrupp vill ni rikta sidan mot?'].Bostadsrättsföreningar
                }
                sx={{ '&:hover': { bgcolor: 'transparent' } }}
              />
            }
            label="Bostadsrättsföreningar"
          />
          <FormControlLabel
            name="Offentligt verksamhet"
            onChange={handleChange}
            control={
              <Checkbox
                checked={
                  formik.values['Vilken målgrupp vill ni rikta sidan mot?']['Offentligt verksamhet']
                }
                sx={{ '&:hover': { bgcolor: 'transparent' } }}
              />
            }
            label="Offentligt verksamhet"
          />
        </FormGroup>
        {formik.touched['Vilken målgrupp vill ni rikta sidan mot?'] &&
          formik.errors['Vilken målgrupp vill ni rikta sidan mot?'] &&
          !(
            formik.values['Vilken målgrupp vill ni rikta sidan mot?'].Privatpersoner ||
            formik.values['Vilken målgrupp vill ni rikta sidan mot?'].Företag ||
            formik.values['Vilken målgrupp vill ni rikta sidan mot?'].Bostadsrättsföreningar ||
            formik.values['Vilken målgrupp vill ni rikta sidan mot?']['Offentligt verksamhet']
          ) && (
            <Typography variant="p" sx={{ color: 'red' }}>
              Detta är ett obligatoriskt fält.
            </Typography>
          )}
        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Lista era tjänster och ge en kort beskrivning till respektive tjänst.
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          name="Lista era tjänster och ge en kort beskrivning till respektive tjänst."
          value={
            formik.values['Lista era tjänster och ge en kort beskrivning till respektive tjänst.']
          }
          onChange={handleChange}
          sx={{ bgcolor: 'white' }}
          minRows={9}
          maxRows={9}
          variant="outlined"
          multiline
          required
          error={Boolean(
            formik.touched[
              'Lista era tjänster och ge en kort beskrivning till respektive tjänst.'
            ] &&
              formik.errors[
                'Lista era tjänster och ge en kort beskrivning till respektive tjänst.'
              ],
          )}
        />
        {formik.touched['Lista era tjänster och ge en kort beskrivning till respektive tjänst.'] &&
          formik.errors[
            'Lista era tjänster och ge en kort beskrivning till respektive tjänst.'
          ] && (
            <Typography variant="p" sx={{ color: 'red' }}>
              {
                formik.errors[
                  'Lista era tjänster och ge en kort beskrivning till respektive tjänst.'
                ]
              }
            </Typography>
          )}
        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Var är ni verksamma när det kommer till uppdrag?
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          name="Var är ni verksamma när det kommer till uppdrag?"
          value={formik.values['Var är ni verksamma när det kommer till uppdrag?']}
          onChange={handleChange}
          sx={{ bgcolor: 'white' }}
          minRows={9}
          maxRows={9}
          variant="outlined"
          multiline
          required
          error={Boolean(
            formik.touched['Var är ni verksamma när det kommer till uppdrag?'] &&
              formik.errors['Var är ni verksamma när det kommer till uppdrag?'],
          )}
        />
        {formik.touched['Var är ni verksamma när det kommer till uppdrag?'] &&
          formik.errors['Var är ni verksamma när det kommer till uppdrag?'] && (
            <Typography variant="p" sx={{ color: 'red' }}>
              {formik.errors['Var är ni verksamma när det kommer till uppdrag?']}
            </Typography>
          )}
        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Annan info som ni tycker är viktigt att få med på sidan?
        </Typography>
        <TextField
          name="Annan info som ni tycker är viktigt att få med på sidan?"
          value={formik.values['Annan info som ni tycker är viktigt att få med på sidan?']}
          onChange={handleChange}
          sx={{ bgcolor: 'white' }}
          minRows={9}
          maxRows={9}
          variant="outlined"
          multiline
          required
        />
        <Typography sx={{ margin: '30px 0 10px', fontWeight: 700 }} variant="h4" component="div">
          Har ni några certifikat, diplom etc? Vänligen skicka in dem till
          produktion@addictivemedia.se eller ladda upp i er bildmapp.
        </Typography>
        <TextField
          name="Har ni några certifikat, diplom etc?"
          value={formik.values['Har ni några certifikat, diplom etc?']}
          onChange={handleChange}
          sx={{ background: 'white' }}
          variant="outlined"
          required
        />
        <Button
          sx={{
            margin: '30px 0',
            fontWeight: 700,
            height: '50px',
            padding: '12px 24px',
            width: '92px',
          }}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => formik.handleSubmit()}>
          {loading ? <Loader /> : 'Skicka'}
        </Button>

        <Divider />
        <Typography sx={{ margin: '20px 0', fontWeight: 700 }} variant="h2" component="div">
          FAQ
        </Typography>
        <Typography sx={{ marginBottom: '16px' }} variant="p">
          Hur skickar jag in mina bilder?
          <br /> Du skickar dina bilder i en separat mapp som du får information kring vid ditt
          onboardingmöte. Har du inte fått en mapp? Kontakta din kontaktperson eller{' '}
          <span style={{ color: 'rgb(220, 161, 13)', fontSize: '16px' }}>
            support@addictivemedia.se
          </span>{' '}
          så hjälper dem dig vidare.
          <br /> <br />
          När är min sida klar? <br /> Vårt produktionsteam går efter beställningslistan, således
          kan ledtiderna variera.
          <br /> <br />
          Kontakta din kontaktperson för mer information om den aktuella ledtiden för tillfället.
          Vanligtvis brukar vi ha ett snitt på cirka 4 veckor, men detta kan variera. Kommer jag få
          se sidan innan den går live på Google?
          <br /> Nej, vi jobbar med live-korrektur. Det betyder att vi publicerar sidan så fort du
          fått information om att din sida är klar. Detta för att inte förlora viktig tid i
          indexeringsfasen. Vi utför eventuella korrigeringar efter vi publicerat och även längre in
          på avtalsperioden.
          <br /> <br />
          Vad är indexering?
          <br /> När en webbsida blir indexerad innebär det att den har blivit registrerad hos en
          sökmotor, vilket är en förutsättning för att den ska vara synlig i sökresultaten. Det tar
          olika lång tid för en sida att bli indexerad och styrs primärt av Google.
          <br /> <br />
          När kommer min sida ligga på plats 1?
          <br /> Vi kan inte lova en specifik placering i sökresultaten, eftersom många variabler,
          inklusive konkurrens och sökmotoralgoritmer, påverkar detta. Dock gör vi alltid vårt
          yttersta för att din landningssida ska vara så väl optimerad som möjligt.
          <br /> <br />
          Varför är det så mycket text på startsidan?
          <br /> När det handlar om sökmotoroptimering (SEO) är startsidan av din webbplats ofta det
          första intrycket som sökmotorer som Google har av din webbplats. Därför är det av yttersta
          vikt att startsidan är optimerad på rätt sätt för att maximera dina chanser att ranka högt
          i sökresultaten. En av de viktigaste aspekterna av SEO är innehåll. Sökmotorer värdesätter
          högkvalitativt och relevant innehåll, och det är här mycket text kommer in i bilden.
          <br /> <br />
          Varför upprepas ett specifikt sökord flera gånger på min landningssida?
          <br />
          Sökord upprepas för att förstärka sidans relevans kring ett specifikt ämne eller en
          sökfråga. Genom att framhäva sökordet kan sidan bli mer synlig i sökmotorresultat, vilket
          kan leda till en högre ranking på platser som Google.
        </Typography>
      </Card>
    </>
  );
};

export default InfoForm;
