import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, CardHeader } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { formatToSEK } from '../../../utils/currencyFormatter';

const customTooltipPlugin = {
  id: 'percentTooltip',
  afterDatasetsDraw(chart) {
    if (chart.id === 1) return;
    const { ctx } = chart;
    const datasetMeta = chart.getDatasetMeta(3);
    if (!datasetMeta) return;

    ctx.save();
    ctx.font = 'bold 10px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';

    chart.data.labels.forEach((_, index) => {
      const totalBudget = chart.data.datasets
        .filter((ds) => ds.stack === 'budget')
        .reduce((sum, ds) => sum + Number(ds.data[index] || 0), 0);
      const totalSales = chart.data.datasets
        .filter((ds) => ds.stack === 'sales')
        .reduce((sum, ds) => sum + Number(ds.data[index] || 0), 0);

      if (totalBudget > 0) {
        const percentage = `${((totalSales / totalBudget) * 100).toFixed(1)}%`;
        const barElement = datasetMeta.data[index];
        if (barElement) {
          const padding = 5;
          const textMetrics = ctx.measureText(percentage);
          const textWidth = textMetrics.width;
          const width = textWidth + 2 * padding;
          const x = barElement.x - width / 2;
          const y = barElement.y - 0;
          const height = 15;

          ctx.fillStyle = 'black';
          ctx.fillText(percentage, x + padding, y - height / 2);
        }
      }
    });
    ctx.restore();
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  customTooltipPlugin,
);

const TotalBudgetCurrentMonth = React.memo(({ data, handleChartClick }) => {
  const chartRef = useRef(null);

  const modifiedData = useMemo(() => ({
    ...data,
    datasets: data.datasets.map((ds) => {
      if (ds.label === 'Churn') {
        return { ...ds, yAxisID: 'y1', hidden: true };
      }
      return ds;
    }),
  }), [data]);

  const handleClick = (event) => {
    if (!chartRef.current) return;
    const chartInstance = chartRef.current;
    const elements = chartInstance.getElementsAtEventForMode(
      event,
      'nearest',
      { intersect: true },
      false,
    );
    if (elements.length > 0) {
      const { datasetIndex, index } = elements[0];
      const dataset = modifiedData.datasets[datasetIndex];
      if (dataset.label === 'Budget - Nykund') return;
      const label = modifiedData.labels[index];
      handleChartClick(dataset.label, label, 'totalBudgetCurrentMonth', null);
    }
  };

  const options = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    onHover(event, chartElement) {
      const nativeTarget = event.native.target;
      nativeTarget.style.cursor = chartElement.length ? 'pointer' : 'default';
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 15,
          padding: 10,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        callbacks: {
          label(context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            if (context.dataset.stack && context.dataset.stack !== 'enddate') {
              const stackDatasets = context.chart.data.datasets.filter(
                (ds) => ds.stack === context.dataset.stack,
              );
              const total = stackDatasets.reduce(
                (acc, ds) => acc + Number(ds.data[context.dataIndex] || 0),
                0,
              );
              return [`${label}: ${formatToSEK(value)}`, `${context.dataset.stack.replace('sales', 'försäljning')[0].toUpperCase() + context.dataset.stack.replace('sales', 'försäljning').slice(1)} - Total: ${formatToSEK(total)}`];
            }
            return `${label}: ${formatToSEK(value)}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: 'rgba(25, 25, 25, 0.35)',
          lineWidth: 1,
          borderWidth: 1,
          drawBorder: true,
        },
        ticks: {
          padding: 5,
          font: {
            weight: 'bold',
          },
        },
      },
      y: {
        stacked: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    datasets: {
      bar: {
        barThickness: undefined,
        maxBarThickness: 60,
        minBarLength: 0,
        stack: undefined,
        categoryPercentage: 0.5,
        barPercentage: 0.9,
      },
    },
  }), []);

  return (
    <Card>
      <CardHeader title="Försäljning - Innevarande månad" />
      <CardContent sx={{ paddingTop: 0 }}>
        <div style={{ minHeight: 300 }}>
          <Bar
            ref={chartRef}
            options={options}
            data={modifiedData}
            onClick={handleClick}
          />
        </div>
      </CardContent>
    </Card>
  );
});

export default TotalBudgetCurrentMonth;
