/**
 * Formats a number to Swedish Krona (SEK) currency.
 *
 * @param {number} amount - The amount to format.
 * @returns {string} The formatted currency string.
 */
function formatToSEK(amount) {
  const formatter = new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(amount);
}

module.exports = {
  formatToSEK,
};
