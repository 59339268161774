import { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, CardHeader } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { formatToSEK } from '../../../utils/currencyFormatter';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LeadChart = ({ data, handleChartClick }) => {
  const chartRef = useRef(null);
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 15,
            padding: 10,
            font: { size: 10 },
          },
        },
        tooltip: {
          callbacks: {
            label(context) {
              const label = context.dataset.label || '';
              const value = context.parsed.y;
              if (context.dataset.stack && context.dataset.stack !== 'enddate') {
                const stackDatasets = context.chart.data.datasets.filter(
                  (ds) => ds.stack === context.dataset.stack,
                );
                const total = stackDatasets.reduce(
                  (acc, ds) => acc + Number(ds.data[context.dataIndex] || 0),
                  0,
                );
                return [
                  `${label}: ${formatToSEK(value)}`,
                  `${context.dataset.stack.replace('sales', 'försäljning')[0].toUpperCase() +
                    context.dataset.stack.replace('sales', 'försäljning').slice(1)} - Total: ${formatToSEK(total)}`,
                ];
              }
              return `${label}: ${formatToSEK(value)}`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            color: 'rgba(25, 25, 25, 0.35)',
            lineWidth: 1,
            borderWidth: 1,
            drawBorder: true,
          },
          ticks: { padding: 5 },
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          barThickness: 10,
        },
        y: { stacked: true },
      },
    }),
    [],
  );

  const handleClick = (event) => {
    if (!chartRef.current) return;
    const chartInstance = chartRef.current;
    const elements = chartInstance.getElementsAtEventForMode(
      event,
      'nearest',
      { intersect: true },
      false,
    );
    if (elements.length > 0) {
      const { datasetIndex, index } = elements[0];
      const dataset = data.datasets[datasetIndex];
      if (dataset.label === 'Budget - Nykund') return;
      const label = data.labels[index];
      handleChartClick(dataset.label, label, 'totalBudgetAll', index);
    }
  };

  return (
    <Card>
      <CardHeader title="Total Försäljning - Per månad" />
      <CardContent sx={{ paddingTop: 0 }}>
        <div style={{ minHeight: '300px' }}>
          <Bar ref={chartRef} options={options} data={data} onClick={handleClick} />
        </div>
      </CardContent>
    </Card>
  );
};

export default LeadChart;
