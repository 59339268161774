import React, { Fragment } from 'react';
import styled from '@emotion/styled';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Box,
  CircularProgress,
  Collapse,
} from '@mui/material';
import { spacing } from '@mui/system';

import OptimizationSitesTable from '../OptimizationSiteTableComponents.js/OptimizationSitesTable';
import { LEADS_EXPECTATION_NAMES } from '../../constants/leadsExpectationNames';
import useOptimizationClientList from '../../hooks/Optimization/useOptimizationClientList';
import AutohideSnackbar from '../../utils/autohideSnackbar';
import { PAYER_STATUS } from '../../constants/payerStatus';
import InfoIconWithTooltip from '../InfoIconWithTooltip';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;

const styleHeaderCell = {
  whiteSpace: 'nowrap',
  width: '300px',
  textAlign: 'end',
  fontWeight: 400,
};

const styleBodyCell = {
  whiteSpace: 'nowrap',
  textAlign: 'end',
};

const OptimizationClientList = () => {
  const {
    companyList,
    page,
    count,
    limit,
    expandedId,
    loadingCell,
    isDesktop,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
    changeComment,
    changeStage,
    openSnackbar,
    setOpenSnackbar,
  } = useOptimizationClientList();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          flexWrap: 'wrap',
        }}>
        <Typography
          sx={{
            width: '100%',
            fontWeight: 500,
            '@media (max-width: 600px)': {
              fontSize: '30px',
            },
          }}
          variant="h2">
          Företag
        </Typography>
      </div>

      <Card
        mb={6}
        sx={{
          maxWidth: '100%',
          '@media (min-width: 960px)': {
            maxWidth: 'calc(100vw - 205px)',
          },
          '@media (min-width: 1280px)': {
            maxWidth: 'calc(100vw - 242px)',
          },
          border: '1px solid',
          borderColor: 'grey.300',
        }}>
        {loadingCell === 0 && (
          <Box sx={{ width: '22px', height: '50px', margin: '50px auto 0' }}>
            <CircularProgress sx={{ width: '100%', height: '100%' }} />
          </Box>
        )}
        {loadingCell === null && (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      fontWeight: 400,
                      position: isDesktop ? 'sticky' : 'static',
                      left: 0,
                      zIndex: 1,
                      background: 'white',
                    }}>
                    Företag
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Live datum</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Nuvarande period</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Uppsägningsdatum</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Slutdatum</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Revenue</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Total leads</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Förväntan</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Leads 30 dagar</div>
                  </TableCell>
                  <TableCell sx={styleHeaderCell}>
                    <div>Antal sidor</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyList?.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow
                      sx={{
                        background:
                          row.payer_status === PAYER_STATUS.BadPayer ? '#ffdedc' : 'white',
                        cursor: 'pointer',
                      }}
                      onClick={() => clickHandler(row.id)}>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          fontWeight: 700,
                          position: isDesktop ? 'sticky' : 'static',
                          left: 0,
                          background:
                            row.payer_status === PAYER_STATUS.BadPayer ? '#ffdedc' : 'white',
                          zIndex: 1,
                          borderBottom: expandedId === row.id && 0,
                          color: row.payer_status === PAYER_STATUS.BadPayer ? 'red' : 'inherit',
                        }}>
                        <span style={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
                          {row.company?.name || ''}
                          {row.payer_status === PAYER_STATUS.Immunity && (
                            <InfoIconWithTooltip
                              title={`Immunity until ${
                                row.company.unpaid_invoice_immunity.split('T')[0]
                              }`}
                            />
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.agreement_start_date?.slice(0, 10) || ''}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.agreement_invoice_start_date?.slice(0, 10) || ''}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.notice_date?.slice(0, 10) || ''}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.agreement_end_date?.slice(0, 10) || ''}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.total_revenue?.toFixed(0) || ''}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.total_leads || 0}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {LEADS_EXPECTATION_NAMES[row.company?.company_leads_expectation_id] || ''}
                      </TableCell>

                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.total_leads_last_30_days}
                      </TableCell>

                      <TableCell
                        sx={{ ...styleBodyCell, borderBottom: expandedId === row.id && 0 }}>
                        {row.company?.total_sites || 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        borderBottom: expandedId === row.id && '1px solid #e0e0e0',
                        backgroundColor: '#fff',
                      }}>
                      <TableCell
                        style={{
                          position: isDesktop ? 'sticky' : 'static',
                          left: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderTop: 'white',

                          borderWidth: 0,
                        }}
                        colSpan={5}>
                        <Collapse in={expandedId === row.id} timeout="auto" unmountOnExit>
                          <OptimizationSitesTable
                            companyName={row.company?.name}
                            comment={row.comment || ''}
                            optimizationsId={row.id}
                            status={row.stage}
                            changeComment={changeComment}
                            changeStage={changeStage}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            {count && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeLimit}
                />
              </div>
            )}
          </TableWrapper>
        )}
      </Card>
      <AutohideSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message="Stage changed"
      />
    </>
  );
};

export default OptimizationClientList;
