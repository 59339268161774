import styled from '@emotion/styled';
import {
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const TableCell = styled(MuiTableCell)`
  padding: 8px;
  white-space: nowrap;
  text-align: end;
`;

const SubscriptionsTable = ({ subscriptions, getSalespersonNamesString }) => (
  <Paper>
    <Table style={{ border: '1px solid #e0e0e0', margin: '15px 0' }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: 'left !important' }}>Live datum</TableCell>
          <TableCell>Nuvarande period</TableCell>
          <TableCell>Uppsägningsdatum</TableCell>
          <TableCell>Slutdatum</TableCell>
          <TableCell>Förnyelsedatum</TableCell>
          <TableCell>Revenue</TableCell>
          <TableCell>Säljare</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptions.map((sub) => (
          <TableRow key={sub.id}>
            <TableCell sx={{ textAlign: 'left !important' }}>
              {sub.agreement_start_date?.slice(0, 10) || ''}
            </TableCell>
            <TableCell>{sub.agreement_invoice_start_date?.slice(0, 10) || ''}</TableCell>
            <TableCell>{sub.notice_date?.slice(0, 10) || ''}</TableCell>
            <TableCell>{sub.agreement_end_date?.slice(0, 10) || ''}</TableCell>
            <TableCell>{sub.renewal_date?.slice(0, 10) || ''}</TableCell>
            <TableCell>{sub.revenue}</TableCell>
            <TableCell>{getSalespersonNamesString(sub.salesperson_id)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>
);

export default SubscriptionsTable;
