import { Layout, Settings, Sliders, UserPlus, Users, ChevronsUp, DollarSign } from 'react-feather';

const pagesSection = [
  {
    href: '/admin/dashboard',
    title: 'Dashboard',
    icon: Sliders,
    children: [
      {
        href: '/admin/dashboard/default',
        title: 'Default',
      },
      {
        href: '/admin/dashboard/sales',
        title: 'Sälj',
      },
      {
        href: '/admin/dashboard/ranking',
        title: 'Ranking',
      },
      {
        href: '/admin/dashboard/user-statistics',
        title: 'User statistics',
      },
      {
        href: '/admin/dashboard/leads',
        title: 'Leads',
      },
    ],
  },
  {
    href: 'sites',
    title: 'Sidor',
    icon: Layout,
  },
  {
    href: 'client',
    title: 'Inlogg kunder',
    icon: Users,
  },
  {
    href: 'kundföretag',
    title: 'Kundföretag',
    icon: Users,
  },
  {
    href: 'leads',
    title: 'Kundförfrågningar',
    icon: UserPlus,
  },
  {
    href: 'settings',
    title: 'Inställningar',
    icon: Settings,
  },
  {
    href: 'users',
    title: 'Användare',
    icon: Users,
  },
  {
    href: 'optimering',
    title: 'Optimering',
    icon: Users,
    children: [
      {
        href: '/admin/optimering/all',
        title: 'Pending',
      },
      {
        href: '/admin/optimering/beställning',
        title: 'Beställning',
      },
      {
        href: '/admin/optimering/production',
        title: 'Production',
      },
      {
        href: '/admin/optimering/live',
        title: 'Live',
      },
      {
        href: '/admin/optimering/lost',
        title: 'Lost',
      },
    ],
  },
  {
    href: 'proposal',
    title: 'Proposals',
    icon: ChevronsUp,
  },
  {
    href: 'invoices',
    title: 'Fakturor',
    icon: DollarSign,
  },
];

const navItems = [
  {
    title: '',
    pages: pagesSection,
  },
];

export default navItems;
