import { TableCell, TableHead, TableRow } from '@mui/material';

const SalesTableHeader = ({ salesType, onClick }) => {
  let dateHeader = 'Datum';
  if (!salesType.toLowerCase().includes('nykund')) {
    dateHeader = 'Förnyelsedatum';
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>Sälj</TableCell>
        <TableCell onClick={onClick} sx={{ whiteSpace: 'nowrap', width: 'auto' }}>
          {dateHeader}
        </TableCell>
        <TableCell sx={{ width: 'auto' }} align="center">
          Värde
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SalesTableHeader;
