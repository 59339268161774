import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { getUserById } from '../../../API/usersApi';
import { formatToSEK } from '../../../utils/currencyFormatter';
import useAuth from '../../../hooks/useAuth';
import { isUser } from '../../../utils/jwt';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const formatUsername = (email) => {
  const namePart = email.split('@')[0];
  const replaced = namePart.replace(/\./g, ' ');
  return replaced
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const TotalBudgetAllUser = ({ data, handleChartClick }) => {
  const [selectedUser, setSelectedUser] = useState('');
  const { userMe } = useAuth();
  const { id } = isUser();
  const chartRef = useRef(null);
  const [userChartData, setUserChartData] = useState({});

  const handleClick = (event) => {
    if (!chartRef.current) return;
    const chartInstance = chartRef.current;
    const elements = chartInstance.getElementsAtEventForMode(
      event,
      'nearest',
      { intersect: true },
      false,
    );
    if (elements.length > 0) {
      const { datasetIndex } = elements[0];
      const dataset = userChartData.datasets[datasetIndex];
      if (dataset.label === 'Budget - Nykund') return;
      handleChartClick(dataset.label, selectedUser, 'totalBudgetAllUser', elements[0].index);
    }
  };

  const getUserData = async () => {
    const loggedIn = await getUserById(id);
    if (loggedIn && loggedIn.role === 3) setSelectedUser(loggedIn.email);
  };

  useEffect(() => {
    if (!userMe) return;
    if (!selectedUser) {
      getUserData();
    }
    if (data && Object.keys(data).length > 0 && !selectedUser) {
      setSelectedUser(Object.keys(data)[0]);
    }
  }, [data, selectedUser]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const chartData = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const monthLabels = Array.from({ length: 12 }, (_, i) => {
      const month = new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' });
      return month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    });
    if (!selectedUser || !data[selectedUser]) {
      return { labels: monthLabels, datasets: [] };
    }
    const userData = data[selectedUser];
    const selectedUserChartData = {
      labels: monthLabels,
      datasets: [
        {
          label: 'Budget - Befintliga kunder',
          data: userData.budget.potential_renewal_clients,
          backgroundColor: 'rgba(130, 41, 108, 0.35)',
          stack: 'budget',
        },
        {
          label: 'Budget - Nykund',
          data: userData.budget.new_client_budget,
          backgroundColor: 'rgba(0, 184, 180, 0.35)',
          stack: 'budget',
        },
        {
          label: 'Försäljning - Befintlig kund',
          data: userData.sales.renewed_result,
          backgroundColor: '#82296C',
          stack: 'sales',
        },
        {
          label: 'Försäljning - Nykund',
          data: userData.sales.new_client_result,
          backgroundColor: '#00B8B4',
          stack: 'sales',
        },
        {
          label: 'Churn',
          data: userData.end_date_clients,
          backgroundColor: 'red',
          stack: 'enddate',
          yAxisID: 'enddate',
        },
      ],
    };
    setUserChartData(selectedUserChartData);
    return selectedUserChartData;
  }, [data, selectedUser]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      onHover(event, chartElement) {
        const nativeTarget = event.native.target;
        nativeTarget.style.cursor = chartElement.length ? 'pointer' : 'default';
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 15,
            padding: 10,
            font: { size: 10 },
          },
        },
        tooltip: {
          callbacks: {
            label(context) {
              const label = context.dataset.label || '';
              const value = context.parsed.y;
              if (context.dataset.stack && context.dataset.stack !== 'enddate') {
                const stackDatasets = context.chart.data.datasets.filter(
                  (ds) => ds.stack === context.dataset.stack,
                );
                const total = stackDatasets.reduce(
                  (acc, ds) => acc + Number(ds.data[context.dataIndex] || 0),
                  0,
                );
                return [
                  `${label}: ${formatToSEK(value)}`,
                  `${context.dataset.stack.replace('sales', 'försäljning')[0].toUpperCase() +
                    context.dataset.stack.replace('sales', 'försäljning').slice(1)} - Total: ${formatToSEK(total)}`,
                ];
              }
              return `${label}: ${formatToSEK(value)}`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            color: 'rgba(25, 25, 25, 0.35)',
            lineWidth: 1,
            borderWidth: 1,
            drawBorder: true,
          },
          ticks: { padding: 5 },
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          barThickness: 10,
        },
        y: {
          stacked: true,
          position: 'left',
        },
        enddate: {
          position: 'right',
          grid: {
            drawOnChartArea: false,
          },
          beginAtZero: true,
        },
      },
    }),
    [],
  );

  return (
    <Card>
      <CardHeader
        title={`${selectedUser ? formatUsername(selectedUser) : 'Välj användare'} - Per Månad`}
        action={
          (
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="user-select-label">Välj användare</InputLabel>
              <Select
                labelId="user-select-label"
                value={selectedUser}
                label="Välj användare"
                onChange={handleUserChange}
              >
                {data &&
                  Object.keys(data).map((user) => (
                    <MenuItem key={user} value={user}>
                      {formatUsername(user)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <div style={{ minHeight: '300px' }}>
          <Bar ref={chartRef} options={options} data={chartData} onClick={handleClick} />
        </div>
      </CardContent>
    </Card>
  );
};

export default TotalBudgetAllUser;
