import { useMemo, useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsersList } from '../API/usersApi';
import { getAllUpsalesSubscriptions, getAllUpsalesOrders, getAllUpsalesQuotas } from '../API/orderApi';

const cherryPickedUsers = [
  'christian.helsing',
  'danielakbari',
  'anton',
  'frank.madsen',
  'bjorn.eriksson',
  'johan',
];

const useAdminDashboardChart = () => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalRes, setTotalRes] = useState({});
  const currentMonthChartDataRef = useRef(null);
  const userYearChartDataRef = useRef(null);
  const yearChartDataRef = useRef(null);

  const { data: userData } = useQuery({
    queryKey: ['usersList'],
    queryFn: () => getUsersList({ page: 0, limit: 20, role: 3 }),
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });

  const { data: quotas } = useQuery({
    queryKey: ['quotas'],
    queryFn: () =>
      getAllUpsalesQuotas({
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
        limit: 1000,
      }),
    staleTime: 60000,
    cacheTime: 60000,
  });

  const { data: upsalesOrders } = useQuery({
    queryKey: ['closeDate'],
    queryFn: () => getAllUpsalesOrders({ closeDate: `${new Date().getFullYear()}` }),
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });

  const { data: subscriptionsData } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: () => getAllUpsalesSubscriptions({ limit: 2000 }),
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });

  useEffect(() => {
    if (!userData) return;
    const activeUsers = userData.users.filter((user) =>
      user.isActive &&
      cherryPickedUsers.includes(user.email.split('@')[0]),
    );
    setFilteredUsers(activeUsers);
  }, [userData]);

  const currentMonthChartData = useMemo(() => {
    if (
      !userData?.users?.length ||
      !quotas?.data ||
      !upsalesOrders?.data ||
      !subscriptionsData?.data ||
      !filteredUsers
    ) {
      return null;
    }

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentYYYYMM = now.toISOString().slice(0, 7);

    const userChartData = filteredUsers.reduce((acc, user) => {
      acc[user.email] = {
        new_client_budget: 0,
        potential_renewal_clients: [],
        new_client_result: [],
        renewed_result: [],
        end_date_clients: [],
      };
      return acc;
    }, {});

    if (!userChartData['johan@addictivemedia.se']) {
      userChartData['johan@addictivemedia.se'] = {
        new_client_budget: 0,
        potential_renewal_clients: [],
        new_client_result: [],
        renewed_result: [],
        end_date_clients: [],
      };
    }

    // Budget - Nykund
    quotas.data.forEach((quota) => {
      if (quota.month === currentMonth + 1 && quota.year === currentYear) {
        const matchingUser = filteredUsers.find(
          ({ email }) => email === quota.user.email,
        );
        if (matchingUser) {
          userChartData[matchingUser.email].new_client_budget += quota.value;
        }
      }
    });

    // Budget - Befintliga kunder
    subscriptionsData.data.forEach((subscription) => {
      const agreementRenewalDate = new Date(subscription.metadata.agreementRenewalDate);
      const agreementEnddate = new Date(subscription.metadata.agreementEnddate);
      if (agreementRenewalDate) {
        if (agreementRenewalDate.toISOString().slice(0, 7) === currentYYYYMM) {
          const matchingUser = filteredUsers.find(
            ({ email }) => email === subscription.user.email,
          );
          if (matchingUser) {
            if (matchingUser.email.includes('anton')) {
              userChartData['johan@addictivemedia.se'].potential_renewal_clients.push(subscription);
            } else {
              userChartData[matchingUser.email].potential_renewal_clients.push(subscription);
            }
          } else if (agreementEnddate.getTime() > agreementRenewalDate.getTime() || (subscription.metadata.agreementEnddate === null || subscription.metadata.agreementEnddate === '' || subscription.metadata.agreementEnddate === undefined)) {
            userChartData['johan@addictivemedia.se'].potential_renewal_clients.push(subscription);
          }
        }
      }
    });

    // Försäljning - Nykund
    upsalesOrders.data.forEach((order) => {
      if (order.stage.name !== 'Affär') return;
      const closeDate = new Date(order.closeDate);
      if (closeDate.toISOString().slice(0, 7) === currentYYYYMM) {
        const matchingUser = filteredUsers.find(
          ({ email }) => email === order.user.email,
        );
        if (matchingUser) {
          userChartData[matchingUser.email].new_client_result.push(order);
        } else {
          userChartData['johan@addictivemedia.se'].new_client_result.push(order);
        }
      }
    });

    // Försäljning - Befintliga kunder
    subscriptionsData.data.forEach((subscription) => {
      const agreementRenewalDate = new Date(subscription.metadata.agreementRenewalDate);
      const newPeriodDate = new Date(subscription.metadata.agreementInvoiceStartdate);
      const initialStartDate = new Date(subscription.metadata.agreementStartdate);
      const agreementEnddate = subscription.metadata.agreementEnddate ? new Date(subscription.metadata.agreementEnddate) : null;
      if (newPeriodDate.toISOString() === initialStartDate.toISOString() && agreementRenewalDate.toISOString().slice(0, 7) !== currentYYYYMM) return;
      if (agreementEnddate && new Date(agreementEnddate).getTime() < new Date(agreementRenewalDate).getTime()) return;
      if (agreementEnddate || subscription.stage.name === 'Paus') return;
      if (agreementRenewalDate) {
        if (agreementRenewalDate.toISOString().slice(0, 7) === currentYYYYMM || newPeriodDate.toISOString().slice(0, 7) === currentYYYYMM) {
          const matchingUser = filteredUsers.find(
            ({ email }) => email === subscription.user.email,
          );
          if (matchingUser) {
            if (matchingUser.email.includes('anton')) {
              userChartData['johan@addictivemedia.se'].renewed_result.push(subscription);
            } else {
              userChartData[matchingUser.email].renewed_result.push(subscription);
            }
          } else {
            userChartData['johan@addictivemedia.se'].renewed_result.push(subscription);
          }
        }
      }
    });

    Object.keys(userChartData).forEach((userEmail) => {
      userChartData[userEmail].renewed_result.forEach((item) => {
        if (!userChartData[userEmail].potential_renewal_clients.includes(item)) {
          const newItem = { ...item, isRenewed: true };
          userChartData[userEmail].potential_renewal_clients.push(newItem);
        }
      });
    });

    // Churn - clients with end date
    subscriptionsData.data.forEach((subscription) => {
      if (subscription.metadata.agreementEnddate) {
        let endDate = new Date(subscription.metadata.agreementEnddate);
        const agreementRenewalDate = subscription.metadata.agreementRenewalDate ?
          new Date(subscription.metadata.agreementRenewalDate) : null;

        // If end date is earlier than renewal date, set to one day before renewal date
        if (agreementRenewalDate && endDate.getTime() < agreementRenewalDate.getTime()) {
          endDate = new Date(agreementRenewalDate.getTime() - 24 * 60 * 60 * 1000);
        }

        // Check if this is the last day of the month
        const lastDayOfMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        const isLastDayOfMonth = endDate.getDate() === lastDayOfMonth;

        // If it's the last day of the month, add one day
        const dateToUse = isLastDayOfMonth
          ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000) // Add one day in milliseconds
          : endDate;

        if (dateToUse.toISOString().slice(0, 7) === currentYYYYMM) {
          const matchingUser = filteredUsers.find(
            ({ email }) => email === subscription.user.email,
          );
          if (matchingUser) {
            if (matchingUser.email.includes('anton')) {
              userChartData['johan@addictivemedia.se'].end_date_clients.push(subscription);
            } else {
              userChartData[matchingUser.email].end_date_clients.push(subscription);
            }
          } else {
            userChartData['johan@addictivemedia.se'].end_date_clients.push(subscription);
          }
        }
      }
    });

    const labels = filteredUsers.map((user) => {
      let name = user.email.split('@')[0];
      if (name.includes('.')) {
        name = name.split('.')[0];
      }
      return name;
    });

    const chartData = {
      agents: labels,
      budget: {
      potentialBudget: filteredUsers.map((user) =>
        (userChartData[user.email]?.potential_renewal_clients || []).reduce(
        (total, client) =>
          total +
          Number(client.yearlyValue) *
          (Number(client.metadata.periodLength) / 12) *
          (user.email.includes('johan') ? 0.55 : 0.7),
        0,
        ),
      ),
      newClientBudget: filteredUsers.map((user) =>
        Number(userChartData[user.email]?.new_client_budget || 0),
      ),
      },
      sales: {
      renewedSales: filteredUsers.map((user) =>
        (userChartData[user.email]?.renewed_result || []).reduce(
        (total, client) =>
          total + Number(client.yearlyValue) * (Number(client.metadata.periodLength) / 12),
        0,
        ),
      ),
      newClientResult: filteredUsers.map((user) =>
        (userChartData[user.email]?.new_client_result || []).reduce(
        (total, order) => total + Number(order.value),
        0,
        ),
      ),
      },
      churn: filteredUsers.map((user) =>
      (userChartData[user.email]?.end_date_clients || []).reduce(
        (total, client) =>
        total + Number(client.yearlyValue) * (Number(client.metadata.periodLength) / 12),
        0,
      ),
      ),
    };

    currentMonthChartDataRef.current = userChartData;

    return {
      labels,
      datasets: [
        {
          label: 'Budget - Befintliga kunder',
          data: chartData.budget.potentialBudget,
          backgroundColor: 'rgba(130, 41, 108, 0.35)',
          stack: 'budget',
        },
        {
          label: 'Budget - Nykund',
          data: chartData.budget.newClientBudget,
          backgroundColor: 'rgba(0, 184, 180, 0.35)',
          stack: 'budget',
        },
        {
          label: 'Försäljning - Befintlig kund',
          data: chartData.sales.renewedSales,
          backgroundColor: '#82296C',
          borderColor: 'rgba(130, 41, 108, 1)',
          borderWidth: 0,
          stack: 'sales',
        },
        {
          label: 'Försäljning - Nykund',
          data: chartData.sales.newClientResult,
          backgroundColor: '#00B8B4',
          borderWidth: 0,
          stack: 'sales',
        },
        {
          label: 'Churn',
          data: chartData.churn,
          backgroundColor: 'red',
          stack: 'enddate',
        },
      ],
    };
  }, [
    userData,
    quotas,
    upsalesOrders,
    subscriptionsData,
    filteredUsers,
  ]);

  const yearChartData = useMemo(() => {
    if (
      !userData?.users?.length ||
      !quotas?.data ||
      !upsalesOrders?.data ||
      !subscriptionsData?.data ||
      !filteredUsers
    ) {
      return null;
    }

    const currentYear = new Date().getFullYear();
    const months = 12;
    const newClientBudgetByMonth = Array(months).fill(0);
    const renewalClientsBudgetByMonth = Array(months).fill(0);
    const newClientResultByMonth = Array(months).fill(0);
    const renewalClientsResultByMonth = Array(months).fill(0);
    const churnByMonth = Array(months).fill(0);

    const quotasByMonth = Array.from({ length: months }, () => []);
    quotas.data.forEach((quota) => {
      if (quota.year === currentYear && quota.month >= 1 && quota.month <= 12) {
        quotasByMonth[quota.month - 1].push(quota);
      }
    });

    const upsalesOrdersByMonth = Array.from({ length: months }, () => []);
    upsalesOrders.data.forEach((order) => {
      if (order.stage.name !== 'Affär') return;
      const closeDate = new Date(order.closeDate);
      if (closeDate.getFullYear() === currentYear) {
        upsalesOrdersByMonth[closeDate.getMonth()].push(order);
      }
    });

    const potentialRenewalClientsByMonth = Array.from({ length: months }, () => []);
    subscriptionsData.data.forEach((subscription) => {
      const agreementRenewalDate = new Date(subscription.metadata.agreementRenewalDate);
      if (agreementRenewalDate.getFullYear() === currentYear) {
        if (subscription.user.email.includes('johan@addictivemedia.se')) {
          if (new Date(subscription.metadata.agreementEnddate).getTime() > agreementRenewalDate.getTime() || (subscription.metadata.agreementEnddate === null || subscription.metadata.agreementEnddate === '' || subscription.metadata.agreementEnddate === undefined)) {
            potentialRenewalClientsByMonth[agreementRenewalDate.getMonth()].push(subscription);
          }
        } else {
          potentialRenewalClientsByMonth[agreementRenewalDate.getMonth()].push(subscription);
        }
      }
    });

    const renewalClientsByMonth = Array.from({ length: months }, () => []);
    subscriptionsData.data.forEach((subscription) => {
      const agreementRenewalDate = new Date(subscription.metadata.agreementRenewalDate);
      const newPeriodDate = new Date(subscription.metadata.agreementInvoiceStartdate);
      const initialStartDate = new Date(subscription.metadata.agreementStartdate);
      const agreementEnddate = subscription.metadata.agreementEnddate ? new Date(subscription.metadata.agreementEnddate) : null;
      if (newPeriodDate.toISOString() === initialStartDate.toISOString()) return;
      if (agreementEnddate && new Date(agreementEnddate).getTime() < new Date(agreementRenewalDate).getTime()) return;
      if (agreementRenewalDate.getFullYear() === currentYear || newPeriodDate.getFullYear() === currentYear) {
        renewalClientsByMonth[agreementRenewalDate.getMonth()].push(subscription);
      }
    });

    const agreementEndClientsByMonth = Array.from({ length: months }, () => []);
    subscriptionsData.data.forEach((subscription) => {
      if (subscription.metadata.agreementEnddate) {
        let endDate = new Date(subscription.metadata.agreementEnddate);
        const agreementRenewalDate = subscription.metadata.agreementRenewalDate ?
          new Date(subscription.metadata.agreementRenewalDate) : null;

        // If end date is earlier than renewal date, set to one day before renewal date
        if (agreementRenewalDate && endDate.getTime() < agreementRenewalDate.getTime()) {
          endDate = new Date(agreementRenewalDate.getTime() - 24 * 60 * 60 * 1000);
        }

        // Check if this is the last day of the month
        const lastDayOfMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        const isLastDayOfMonth = endDate.getDate() === lastDayOfMonth;

        // If it's the last day of the month, add one day
        const dateToUse = isLastDayOfMonth
          ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000) // Add one day in milliseconds
          : endDate;

        if (dateToUse.getFullYear() === currentYear) {
          agreementEndClientsByMonth[dateToUse.getMonth()].push(subscription);
        }
      }
    });

    const dataChartObject = {
      agents: [],
      budget: {
        potentialRenewalClients: potentialRenewalClientsByMonth,
        newClientBudget: newClientBudgetByMonth,
      },
      sales: {
        newClientResult: upsalesOrdersByMonth,
        renewedSales: renewalClientsByMonth,
      },
      churn: agreementEndClientsByMonth,
    };

    for (let month = 0; month < months; month += 1) {
      quotasByMonth[month].forEach((quota) => {
        newClientBudgetByMonth[month] += quota.value;
      });

      upsalesOrdersByMonth[month].forEach((order) => {
        newClientResultByMonth[month] += order.value;
      });

      potentialRenewalClientsByMonth[month].forEach((subscription) => {
        const multiplier = subscription.user.email.includes('johan@addictivemedia.se') ? 0.55 : 0.7;
        renewalClientsBudgetByMonth[month] +=
          subscription.yearlyValue * (subscription.metadata.periodLength / 12) * multiplier;
      });

      renewalClientsByMonth[month].forEach((subscription) => {
        if (!subscription.metadata.agreementEnddate) {
          renewalClientsResultByMonth[month] +=
            subscription.yearlyValue * (subscription.metadata.periodLength / 12);
        }
      });

      agreementEndClientsByMonth[month].forEach((subscription) => {
        churnByMonth[month] += subscription.yearlyValue * (subscription.metadata.periodLength / 12);
      });
    }

    const labels = Array.from({ length: 12 }, (_, i) => {
      const month = new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' });
      return month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    });

    yearChartDataRef.current = dataChartObject;

    return {
      labels,
      datasets: [
        {
          label: 'Budget - Befintliga kunder',
          data: renewalClientsBudgetByMonth,
          backgroundColor: 'rgba(130, 41, 108, 0.35)',
          stack: 'budget',
        },
        {
          label: 'Budget - Nykund',
          data: newClientBudgetByMonth,
          backgroundColor: 'rgba(0, 184, 180, 0.35)',
          stack: 'budget',
        },
        {
          label: 'Försäljning - Befintlig kund',
          data: renewalClientsResultByMonth,
          backgroundColor: '#82296C',
          borderColor: 'rgba(106, 159, 233, 1)',
          borderWidth: 0,
          stack: 'sales',
        },
        {
          label: 'Försäljning - Nykund',
          data: newClientResultByMonth,
          backgroundColor: '#00B8B4',
          borderWidth: 0,
          stack: 'sales',
        },
        {
          label: 'Churn',
          data: churnByMonth,
          backgroundColor: 'red',
          stack: 'enddate',
        },
      ],
    };
  }, [
    userData,
    quotas,
    upsalesOrders,
    subscriptionsData,
    filteredUsers,
  ]);

  const userYearChartData = useMemo(() => {
    if (
      !userData?.users?.length ||
      !quotas?.data ||
      !upsalesOrders?.data ||
      !subscriptionsData?.data ||
      !filteredUsers
    ) {
      return null;
    }

    const currentYear = new Date().getFullYear();
    const userChartData = filteredUsers.reduce((acc, user) => {
      acc[user.email] = {
        new_client_budget: Array(12).fill(0),
        potential_renewal_clients: Array.from({ length: 12 }, () => []),
        new_client_result: Array.from({ length: 12 }, () => []),
        renewed_result: Array.from({ length: 12 }, () => []),
        end_date_clients: Array.from({ length: 12 }, () => []),
      };
      return acc;
    }, {});

    if (!userChartData['johan@addictivemedia.se']) {
      userChartData['johan@addictivemedia.se'] = {
        new_client_budget: Array(12).fill(0),
        potential_renewal_clients: Array.from({ length: 12 }, () => []),
        new_client_result: Array.from({ length: 12 }, () => []),
        renewed_result: Array.from({ length: 12 }, () => []),
        end_date_clients: Array.from({ length: 12 }, () => []),
      };
    }

    quotas.data.forEach((quota) => {
      if (quota.year === currentYear) {
        const matchingUser = filteredUsers.find(
          ({ email }) => email === quota.user.email,
        );
        if (matchingUser) {
          userChartData[matchingUser.email].new_client_budget[quota.month - 1] += quota.value;
        }
      }
    });

    subscriptionsData.data.forEach((subscription) => {
      const agreementRenewalDate = new Date(subscription.metadata.agreementRenewalDate);
      const agreementEnddate = new Date(subscription.metadata.agreementEnddate);
      if (agreementRenewalDate.getFullYear() === currentYear) {
        const matchingUser = filteredUsers.find(
          ({ email }) => email === subscription.user.email,
        );
        if (matchingUser) {
          if (matchingUser.email.includes('anton')) {
            userChartData['johan@addictivemedia.se'].potential_renewal_clients[agreementRenewalDate.getMonth()].push(subscription);
          } else {
            userChartData[matchingUser.email].potential_renewal_clients[agreementRenewalDate.getMonth()].push(subscription);
          }
        } else if (agreementEnddate.getTime() > agreementRenewalDate.getTime() || (subscription.metadata.agreementEnddate === null || subscription.metadata.agreementEnddate === '' || subscription.metadata.agreementEnddate === undefined)) {
          userChartData['johan@addictivemedia.se'].potential_renewal_clients[agreementRenewalDate.getMonth()].push(subscription);
        }
      }
    });

    upsalesOrders.data.forEach((order) => {
      if (order.stage.name !== 'Affär') return;
      const closeDate = new Date(order.closeDate);
      if (closeDate.getFullYear() === currentYear) {
        const matchingUser = filteredUsers.find(
          ({ email }) => email === order.user.email,
        );
        if (matchingUser) {
          userChartData[matchingUser.email].new_client_result[closeDate.getMonth()].push(order);
        } else {
          userChartData['johan@addictivemedia.se'].new_client_result[closeDate.getMonth()].push(order);
        }
      }
    });

    subscriptionsData.data.forEach((subscription) => {
      const agreementRenewalDate = new Date(subscription.metadata.agreementRenewalDate);
      const newPeriodDate = new Date(subscription.metadata.agreementInvoiceStartdate);
      const initialStartDate = new Date(subscription.metadata.agreementStartdate);
      const agreementEnddate = subscription.metadata.agreementEnddate ? new Date(subscription.metadata.agreementEnddate) : null;
      if (newPeriodDate.toISOString() === initialStartDate.toISOString()) return;
      if (agreementEnddate && new Date(agreementEnddate).getTime() < new Date(agreementRenewalDate).getTime()) return;
      if (agreementRenewalDate.getFullYear() === currentYear || newPeriodDate.getFullYear() === currentYear) {
        const matchingUser = filteredUsers.find(
          ({ email }) => email === subscription.user.email,
        );
        if (matchingUser) {
          if (matchingUser.email.includes('anton')) {
            userChartData['johan@addictivemedia.se'].renewed_result[agreementRenewalDate.getMonth()].push(subscription);
          } else {
            userChartData[matchingUser.email].renewed_result[agreementRenewalDate.getMonth()].push(subscription);
          }
        } else {
          userChartData['johan@addictivemedia.se'].renewed_result[agreementRenewalDate.getMonth()].push(subscription);
        }
      }
    });

    Object.keys(userChartData).forEach((userEmail) => {
      userChartData[userEmail].renewed_result.forEach((item) => {
        item.forEach((sub) => {
          if (!userChartData[userEmail].potential_renewal_clients[new Date(sub.metadata.agreementRenewalDate).getMonth()].includes(sub)) {
            const newItem = { ...sub, isRenewed: true };
            userChartData[userEmail].potential_renewal_clients[new Date(sub.metadata.agreementRenewalDate).getMonth()].push(newItem);
          }
        });
      });
    });

    subscriptionsData.data.forEach((subscription) => {
      if (subscription.metadata.agreementEnddate) {
        let endDate = new Date(subscription.metadata.agreementEnddate);
        const agreementRenewalDate = subscription.metadata.agreementRenewalDate ?
          new Date(subscription.metadata.agreementRenewalDate) : null;

        // If end date is earlier than renewal date, set to one day before renewal date
        if (agreementRenewalDate && endDate.getTime() < agreementRenewalDate.getTime()) {
          endDate = new Date(agreementRenewalDate.getTime() - 24 * 60 * 60 * 1000);
        }

        // Check if this is the last day of the month
        const lastDayOfMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        const isLastDayOfMonth = endDate.getDate() === lastDayOfMonth;

        // If it's the last day of the month, add one day
        const dateToUse = isLastDayOfMonth
          ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000) // Add one day in milliseconds
          : endDate;

        if (dateToUse.getFullYear() === currentYear) {
          const matchingUser = filteredUsers.find(
            ({ email }) => email === subscription.user.email,
          );
          if (matchingUser) {
            if (matchingUser.email.includes('anton')) {
              userChartData['johan@addictivemedia.se'].end_date_clients[dateToUse.getMonth()].push(subscription);
            } else {
              userChartData[matchingUser.email].end_date_clients[dateToUse.getMonth()].push(subscription);
            }
          } else {
            userChartData['johan@addictivemedia.se'].end_date_clients[dateToUse.getMonth()].push(subscription);
          }
        }
      }
    });

    const monthLabels = Array.from({ length: 12 }, (_, i) => {
      const month = new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' });
      return month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    });

    const chartData = filteredUsers.reduce((acc, user) => {
      acc[user.email] = {
      labels: monthLabels,
      budget: {
        potential_renewal_clients: monthLabels.map((_, month) => {
        const subs = userChartData[user.email]?.potential_renewal_clients[month] || [];
        return subs.reduce(
          (total, client) =>
          total +
          Number(client.yearlyValue) *
            (Number(client.metadata.periodLength) / 12) *
            (user.email.includes('johan') ? 0.55 : 0.7),
          0,
        );
        }),
        new_client_budget: monthLabels.map((_, month) =>
        Number(userChartData[user.email]?.new_client_budget[month] || 0),
        ),
      },
      sales: {
        renewed_result: monthLabels.map((_, month) => {
        const subs = userChartData[user.email]?.renewed_result[month] || [];
        return subs.reduce(
          (total, sub) =>
          total + Number(sub.yearlyValue) * (Number(sub.metadata.periodLength) / 12),
          0,
        );
        }),
        new_client_result: monthLabels.map((_, month) => {
        const orders = userChartData[user.email]?.new_client_result[month] || [];
        return orders.reduce((total, order) => total + Number(order.value), 0);
        }),
      },
      end_date_clients: monthLabels.map((_, month) => {
        const subs = userChartData[user.email]?.end_date_clients[month] || [];
        return subs.reduce(
        (total, client) =>
          total + Number(client.yearlyValue) * (Number(client.metadata.periodLength) / 12),
        0,
        );
      }),
      };
      return acc;
    }, {});

    userYearChartDataRef.current = userChartData;

    return chartData;
  }, [
    userData,
    quotas,
    upsalesOrders,
    subscriptionsData,
    filteredUsers,
  ]);

  useEffect(() => {
    if (!currentMonthChartData) return;

    const getSum = (label) => {
      const dataset = currentMonthChartData.datasets.find((d) => d.label === label);
      return dataset ? dataset.data.reduce((acc, val) => acc + Number(val), 0) : 0;
    };

    const newClientBudget = getSum('Budget - Nykund');
    const renewedBudget = getSum('Budget - Befintliga kunder');
    const newClientResult = getSum('Försäljning - Nykund');
    const renewedResult = getSum('Försäljning - Befintlig kund');

    const computedTotal = {
      newClient: {
        budget: newClientBudget,
        result: newClientResult,
        percentage: newClientBudget ? ((newClientResult / newClientBudget) * 100).toFixed(2) : '0.00',
      },
      renewed: {
        budget: renewedBudget,
        result: renewedResult,
        percentage: renewedBudget ? ((renewedResult / renewedBudget) * 100).toFixed(2) : '0.00',
      },
      total: {
        budget: newClientBudget + renewedBudget,
        result: newClientResult + renewedResult,
        percentage: (newClientBudget + renewedBudget) ? (((newClientResult + renewedResult) / (newClientBudget + renewedBudget)) * 100).toFixed(2) : '0.00',
      },
    };

    setTotalRes(computedTotal);
  }, [currentMonthChartData]);

  return { currentMonthChartData, userYearChartData, yearChartData, currentMonthChartDataRef, userYearChartDataRef, yearChartDataRef, filteredUsers, totalRes };
};

export default useAdminDashboardChart;
