import React from 'react';
import SlideInComponentWrapper from '../styledComponents/SlideInComponentWrapper';
import SalesListContainer from '../../containers/sales/SalesListContainer';

const SlideInComponent = ({ data, isActive }) => {
  if (!isActive || !data) return null;
  return (
    <div>
      <SlideInComponentWrapper>
        <div className={`slide-in ${isActive ? 'active' : ''}`}>
          <div className="slide-in-content">
            <SalesListContainer salesData={data} />
          </div>
        </div>
      </SlideInComponentWrapper>
    </div>
  );
};

export default SlideInComponent;
