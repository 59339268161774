import { useEffect, useRef, useState } from 'react';
import { Grid, Typography as MuiTypography } from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';

import useAdminDashboardChart from '../../hooks/useAdminDashboardChart';
import Loader from '../Loader';
import TotalBudgetCurrentMonth from './SalesCharts/TotalBudgetCurrentMonth';
import TotalBudgetAll from './SalesCharts/TotalBudgetAll';
import TotalBudgetAllUser from './SalesCharts/TotalBudgetAllUser';
import SlideInComponent from './SlideInComponent';
import useAuth from '../../hooks/useAuth';

const Typography = styled(MuiTypography)(spacing);

const SalesPage = () => {
  const slideInRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [salesData, setSalesData] = useState(null);
  const { userMe } = useAuth();

  const { currentMonthChartData, yearChartData, userYearChartData, currentMonthChartDataRef, userYearChartDataRef, yearChartDataRef, filteredUsers, totalRes } = useAdminDashboardChart();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (slideInRef.current && !slideInRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleChartClick = (event, activeUser, graph, index) => {
    let userChartDataForUser;
    const activeSalesData = { event, activeUser, data: [] };
    const matchingUser = filteredUsers.find((user) => user.email.includes(activeUser));
    if (graph !== 'totalBudgetAll' && !matchingUser) return;

    let graphChartData;
    if (graph === 'totalBudgetAllUser') {
      graphChartData = userYearChartDataRef.current;
    } else if (graph === 'totalBudgetAll') {
      graphChartData = yearChartDataRef.current;
    } else {
      graphChartData = currentMonthChartDataRef.current;
    }
    if (graph !== 'totalBudgetAll') {
      userChartDataForUser = graphChartData[matchingUser.email];
    } else {
      userChartDataForUser = graphChartData;
    }

    if (graph === 'totalBudgetAll') {
      switch (event) {
        case 'Försäljning - Nykund':
          activeSalesData.data = userChartDataForUser.sales.newClientResult[index];
          break;
        case 'Försäljning - Befintlig kund':
          activeSalesData.data = userChartDataForUser.sales.renewedSales[index];
          break;
        case 'Budget - Befintliga kunder':
          activeSalesData.data = userChartDataForUser.budget.potentialBudget[index];
          break;
        case 'Budget - Nykund':
          activeSalesData.data = userChartDataForUser.budget.newClientBudget[index];
          break;
        case 'Churn':
          activeSalesData.data = userChartDataForUser.churn[index];
          break;
        default:
          break;
      }
    } else if (index !== null) {
      switch (event) {
        case 'Försäljning - Nykund':
          activeSalesData.data = userChartDataForUser.new_client_result[index];
          break;
        case 'Försäljning - Befintlig kund':
          activeSalesData.data = userChartDataForUser.renewed_result[index];
          break;
        case 'Budget - Befintliga kunder':
          activeSalesData.data = userChartDataForUser.potential_renewal_clients[index];
          break;
        case 'Budget - Nykund':
          activeSalesData.data = userChartDataForUser.new_client_budget[index];
          break;
        case 'Churn':
          activeSalesData.data = userChartDataForUser.end_date_clients[index];
          break;
        default:
          break;
      }
    } else {
      switch (event) {
        case 'Försäljning - Nykund':
          activeSalesData.data = userChartDataForUser.new_client_result;
          break;
        case 'Försäljning - Befintlig kund':
          activeSalesData.data = userChartDataForUser.renewed_result;
          break;
        case 'Budget - Befintliga kunder':
          activeSalesData.data = userChartDataForUser.potential_renewal_clients;
          break;
        case 'Budget - Nykund':
          activeSalesData.data = userChartDataForUser.new_client_budget;
          break;
        case 'Churn':
          activeSalesData.data = userChartDataForUser.end_date_clients;
          break;
        default:
          break;
      }
    }

    setSalesData(activeSalesData);
    setIsActive(true);
  };

  if (!currentMonthChartData || !yearChartData || !userYearChartData || !currentMonthChartDataRef || !userYearChartDataRef || !yearChartDataRef || !filteredUsers || !totalRes) {
    return <Loader />;
  }
  return (
    <>
      <div ref={slideInRef}>
        <SlideInComponent data={salesData} isActive={isActive} />
      </div>
      <Grid container justifyContent="space-between" spacing={6}>
        <Grid item sx={{ marginBottom: '20px' }}>
          <Typography
            sx={{
              fontWeight: 500,
              '@media (max-width: 600px)': {
                fontSize: '30px',
              },
            }}
            variant="h1"
            gutterBottom
          >
            Addictive Media - Sälj
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <TotalBudgetCurrentMonth data={currentMonthChartData} handleChartClick={handleChartClick} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TotalBudgetAllUser data={userYearChartData} handleChartClick={handleChartClick} />
        </Grid>
        {userMe === 'admin' && (
          <Grid item xs={12} lg={6}>
            <TotalBudgetAll data={yearChartData} handleChartClick={handleChartClick} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default SalesPage;
