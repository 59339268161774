import styled from '@emotion/styled';
import { Card, CardContent, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import { spacing } from '@mui/system';
import LeadsPerMonthSegmentSelect from './LeadPerMonthSegmentSelect';
import Loader from '../../Loader';

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

const LeadsPerMonthForLast2YearsChart = ({ chartData, category, setCategory }) => {
  if (!chartData || !chartData.comparison || chartData.comparison.length === 0) {
    return (
      <Card sx={{ maxWidth: '600px', height: '450px' }} mb={1}>
        <Loader />
      </Card>
    );
  }

  const reversedData = [...chartData.comparison.slice(1)].reverse();

  const categories = reversedData.map((item) => item.month);
  const growthLowData = reversedData.map((item) => Math.min(item.growthLow || 0, 250));
  const growthHighData = reversedData.map((item) => Math.min(item.growthHigh || 0, 250));

  const series = [
    {
      name: 'Growth Low',
      data: growthLowData,
      color: '#FF0000',
    },
    {
      name: 'Growth High',
      data: growthHighData,
      color: '#008000',
    },
  ];

  const options = {
    chart: {
      zoom: { enabled: false },
      toolbar: {
        show: false,
      },
    },
    dataLabels: { enabled: false },
    stroke: {
      width: 3,
      curve: 'straight',
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
        },
        rotateAlways: true,
      },
      axisTicks: { show: true },
      axisBorder: { show: true },
    },
    yaxis: {
      labels: {
        formatter: (val) => `${Math.round(val)}%`,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(2)}%`,
      },
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  return (
    <Card sx={{ maxWidth: '600px' }} mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Leadskategori: Utveckling antal kunder i jmf med året innan.
        </Typography>
        <LeadsPerMonthSegmentSelect category={category} onChange={setCategory} />
        <Spacer mb={6} />
        <ChartWrapper>
          <Chart options={options} series={series} type="line" height="350" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default LeadsPerMonthForLast2YearsChart;
