import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsersList } from '../API/usersApi';

const useUserStatisticsTable = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');

  const aggregateUsersByCompany = (users) => {
    let idx = 0;
    const companyMap = users.reduce((acc, user) => {
      const companyName = user.companyName || 'Unknown';

      if (!acc[companyName]) {
        acc[companyName] = {
          id: idx += 1,
          companyName,
          userCount: 0,
          totalLogins: 0,
          lastLogin: null,
          users: [],
        };
      }

      acc[companyName].userCount += 1;

      if (user.count_last_30_days_login) {
        acc[companyName].totalLogins += parseInt(user.count_last_30_days_login, 10) || 0;
      }

      if (user.lastLogin) {
        const loginDate = new Date(user.lastLogin);
        if (!acc[companyName].lastLogin || loginDate > new Date(acc[companyName].lastLogin)) {
          acc[companyName].lastLogin = user.lastLogin;
        }
      }

      acc[companyName].users.push(user);

      return acc;
    }, {});

    return Object.values(companyMap);
  };

  const searchInputHandler = (e) => {
    const value = e.target.value;
    setSearch(value);
    setPage(0);
  };

  const { data: { users = [], count = 0 } = {} } = useQuery(
    ['userStatisticsTable', { search, page, limit }],
    () =>
      getUsersList({
        search: search.toLowerCase(),
        page,
        limit,
        role: 2,
        countActions: 'true',
        getLastLogin: 'true',
        sort: 'count_last_30_days_login',
        searchParams: 'companyName',
      }),
    {
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  const companies = aggregateUsersByCompany(users);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  return {
    companies,
    count,
    page,
    search,
    limit,
    handleChangePage,
    searchInputHandler,
    handleChangeLimit,
  };
};

export default useUserStatisticsTable;
