import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Loader from '../../Loader';
import {
  getLeadsExpectationChartData,
  getLeadsPerMonthChartData,
  getLeadsPerMonthForLast2YearsChartData,
} from '../../../API/dashboardApi';
import LeadExpectationChart from './LeadExpectationChart';
import LeadsPerMonthChart from './LeadsPerMonthChart';
import { LeadsDashboardContext } from '../../../contexts/dashboard/LeadsDashboardContext';
import LeadsPerMonthForLast2YearsChart from './LeadsPerMonthForLast2YearsChart';

const LeadExpectationPage = () => {
  const [p2yCategory, setp2yCategory] = useState(0);
  const {
    dates: { startDate, endDate },
    category,
  } = useContext(LeadsDashboardContext);
  const { data: stats, isLoading } = useQuery({
    queryKey: ['leadsExpectationChartData'],
    queryFn: () => getLeadsExpectationChartData(),
    staleTime: 60000,
  });
  const { data: leadsPerMonth, isLoading: isLoadingLeads } = useQuery({
    queryKey: ['leadsPerMonthChartData', startDate, endDate, category],
    queryFn: () => getLeadsPerMonthChartData({ startDate, endDate, category }),
    staleTime: 60000,
  });

  const { data: leadsPerMonth2Years, isLoading: isLoadingLeads2Years } = useQuery({
    queryKey: ['leadsPerMonthForLast2YearsChartData', p2yCategory],
    queryFn: () => getLeadsPerMonthForLast2YearsChartData({ category: p2yCategory }),
    staleTime: 60000,
  });
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <LeadExpectationChart data={stats} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LeadsPerMonthChart data={leadsPerMonth} isLoadingLeads={isLoadingLeads} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LeadsPerMonthForLast2YearsChart
          chartData={leadsPerMonth2Years}
          category={p2yCategory}
          setCategory={setp2yCategory}
          isLoading={isLoadingLeads2Years}
        />
      </Grid>
    </Grid>
  );
};

export default LeadExpectationPage;
