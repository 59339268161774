import { Select, MenuItem } from '@mui/material';
import { useCategories } from '../../../hooks/useCategories';

const LeadsPerMonthSegmentSelect = ({ category, onChange }) => {
  const { data: categories } = useCategories();
  if (!categories?.data || !categories.data.length) return null;
  return (
    <Select
      size="small"
      value={category}
      onChange={(e) => onChange(e.target.value)}
      style={{ maxWidth: '150px', height: '36px' }}>
      <MenuItem key={0} value={0}>
        All Segments
      </MenuItem>
      {categories?.data.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.category_name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LeadsPerMonthSegmentSelect;
